import axios from "@/services/axios";
import UrlKeeper from "@/services/UrlKeeper.js";
import _ from "lodash";

class ImporterService {
  constructor() { }

  list() {
    const apiUrl = UrlKeeper.getUrl();

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + "/importer/items/list").then((response) => {
        if (response.success) {
          resolve(response.results);
        }
      });
    });
  }

  getExampleFile(entity) {
    const apiUrl = UrlKeeper.getUrl();

    return new Promise((resolve, reject) => {
      axios.postWithDownload(apiUrl + "/importer/example/" + entity, undefined, entity + "_importer.xlsx")
        .then((response) => {
          resolve(response);
        });
    });
  }

  preset(type) {
    const apiUrl = UrlKeeper.getUrl();

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + "/importer/preset/" + type).then((response) => {
        console.log(response)
        resolve(response.success);
      });
    });
  }

  upload_2() {
    const apiUrl = UrlKeeper.getUrl();

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + "/importer/items/upload_2").then((response) => {
        if (response.success) {
          resolve(response.results);
        }
      });
    });
  }

  uploadFromFile(file, entity, userId) {
    const apiUrl = UrlKeeper.getUrl()
    return new Promise((resolve, reject) => {

      var form = new FormData();
      form.append('imports', file);

      axios
        .post(apiUrl + "/importer/" + entity + "/upload?userId=" + userId, form)
        .then((response) => {
          if (response.success) {
            resolve({
              rowsImported: response.results.rowsImported,
              rowsInError: response.results.rowsInError,
            });
          } else
            reject({
              rowsImported: response.results.rowsImported,
              rowsInError: response.results.rowsInError,
            });
        });
    });
  }

  unloadFromFile(fileInfo, itemsToUnload, entity, userId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/importer/" + entity + "/unload", {
          userId: userId,
          fileName: fileInfo.name,
          extension: fileInfo.extension,
          entityTableName: entity,
          items: itemsToUnload,
        })
        .then((response) => {
          if (response.success) {
            resolve({
              rowsImported: response.results.rowsImported,
              rowsInError: response.results.rowsInError,
            });
          } else
            reject({
              rowsImported: response.results.rowsImported,
              rowsInError: response.results.rowsInError,
            });
        });
    });
  }
  _readFile(fileToImport, _builder, inUpload) {
    return new Promise((resolve, reject) => {
      try {
        const self = this;
        const input = fileToImport;
        const reader = new FileReader();
        let text = "";
        reader.readAsText(input);
        reader.onload = function (e) {
          text = e.target.result;
          self._csvToArray(text, _builder, inUpload).then((_itemsReaded) => {
            resolve(_itemsReaded);
          });
        };
      } catch (err) {
        reject(err);
      }
    });
  }

  _csvToArray(str, _builder, inUpload, delimiter = ";") {
    return new Promise((resolve, reject) => {
      try {
        const headers = str.slice(0, str.indexOf("\n")).split(delimiter);

        const rows = str.slice(str.indexOf("\n") + 1).split("\n");
        rows.splice(0, 1);
        const arr = rows.map(function (row) {
          const values = row.split(delimiter);
          const el = headers.reduce(function (object, header, index) {
            if (values[index] === "null") object[header] = null;
            else object[header] = values[index];

            return object;
          }, {});
          if (el[Object.keys(el)[0]] !== "") return el;
        });

        var _arr = arr.filter((item) => item !== undefined);

        _builder(_arr, inUpload).then((obj) => {
          resolve(obj);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  _buildItemObjectFromFile(fileItems, inUpload) {
    return new Promise((resolve, reject) => {
      var today = new Date();
      let k = 0;
      const itemToImport = inUpload
        ? fileItems.map(function (item) {
          k++;
          let _item = item;
          return {
            key: k,
            code: _item.code,
            description: _item.description,
            priceSelling: _item.priceSelling
              ? parseFloat(_item.priceSelling.replace(",", "."))
              : 0,
            pricePurchase: _item.pricePurchase
              ? parseFloat(_item.pricePurchase.replace(",", "."))
              : 0,
            priceNetPurchase: _item.priceNetPurchase
              ? parseFloat(_item.priceNetPurchase.replace(",", "."))
              : 0,
            discount: _item.discount
              ? parseFloat(_item.discount.replace(",", "."))
              : 0,
            isInternal: _item.isInternal,
            toCheck: _item.toCheck,
            qtyToImport: _item.qtyToImport ? Number(_item.qtyToImport) : 0,
            qtyMinStock: _item.qtyMinStock ? Number(_item.qtyMinStock) : 0,
            qtyTidying: _item.qtyTidying ? Number(_item.qtyTidying) : 0,
            package_types: _item.package_types,
            createdAt: new Date(today),
            lastPurchaseDate: new Date(today),
            tag: _item.tag,
            tagCategory: _item.tagCategory,
            suppliers: _item.suppliers,
            departments: _item.departments,
            unit_of_measures: _item.unitOfMeasures,
            associated_codes: [...(_item.associatedCodes.split(','))],
          };
        })
        : fileItems.map(function (item) {
          k++;
          let _item = item;
          return {
            key: k,
            code: _item.code,
            description: _item.description,
            qtyToUnload: _item.qtyToUnload,
          };
        });

      resolve(itemToImport);
    });
  }
}
export default new ImporterService();
