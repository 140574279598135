module.exports = {
  FileExtensionNotCorrectCsv: {
    key: "FileExtensionNotCorrect",
    desc:
      "Inserire tipo file corretto (csv)",
  },
  SuccessfullyImport: {
    key: "SuccessfullyImport",
    title: "Importazione avvenuta con successo",
    desc(rowsImported, rowsInError){
      return 'I servizi importati sono ' + rowsImported + '. Quelli andati in errore sono ' + rowsInError + '. Sono state anche importate le relative le Categorie Tag e Tag per l\'analisi statistica, mentre le famiglie e Reparti per l\'organizzazione dei servizi ';
    }
  },
  SuccessfullyImportOperators: {
    key: "SuccessfullyImport",
    title: "Importazione operatori avvenuta con successo",
    desc(rowsImported, rowsInError){
      return 'Gli operatori importati sono ' + rowsImported + '. Quelli andati in errore sono ' + rowsInError + '. Sono state anche importate le relative le Categorie Tag, Tag, Calendari e Barcode';
    }
  },
  SuccessfullyImportCustomers: {
    key: "SuccessfullyImport",
    title: "Importazione clienti avvenuta con successo",
    desc(rowsImported, rowsInError){
      return 'I clienti importati sono ' + rowsImported + '. Quelli andati in errore sono ' + rowsInError;
    }
  },
  SuccessfullyImportCalendar:{
    key: "SuccessfullyImport",
    title: "Importazione calendari avvenuta con successo",
    desc(rowsImported, rowsInError){
      return 'Gli eventi importati sono ' + rowsImported + '. Quelli andati in errore sono ' + rowsInError;
    }
  },
  SuccessfullyImportPayments:{
    key: "SuccessfullyImport",
    title: "Importazione pagamenti avvenuta con successo",
    desc(rowsImported, rowsInError){
      return 'I pagamenti importati sono ' + rowsImported + '. Quelli andati in errore sono ' + rowsInError;
    }
  },
  SuccessfullyImportInvoices:{
    key: "SuccessfullyImport",
    title: "Importazione fatture avvenuta con successo",
    desc(rowsImported, rowsInError){
      return 'Le fatture importate sono ' + rowsImported + '. Quelli andati in errore sono ' + rowsInError;
    }
  },
  ErrorImport: {
    key: "ErrorImport",
    title: "Errore Inserimento File",
    desc(err){
      return 'Errore durante l\'importatione. Errore: ' + err;  
    }
  },
}