<template>
  <div>
    <v-text-field
      max-width="22px"
      disabled
      v-model="fileName"
      dense
      filled
      label="Nome File (max 500 righe)"
      prepend-icon="mdi-file"
      clearable
      hide-details="auto"
    ></v-text-field>
    <div class="d-flex aling-center justify-center">
      <v-chip :color="color" x-large class="mt-2" text @click="uploadFile">
        Carica File (max 500 righe)
      </v-chip>
      <v-chip v-if="!!exampleType" :color="color" x-large class="mt-2 ml-2" text @click="downloadExample(exampleType)">
        Scarica esempio
      </v-chip>
    </div>
  </div>
</template>

<script>
import importerService from "@/services/importer/importer.service";
import fileLoader from "@/services/files.js";

export default {
  name: "FileUploader",
  data: function () {
    return {
      fileName: undefined,
      localFileUrl: this.fileUrl,
      runningLoader: undefined,
    };
  },
  props: {
    value: {},
    fileUrl: {
      type: String,
    },
    color: {
      type: String,
      default: "primary",
    },
    fileRequireToken: {
      type: Boolean,
      default: true,
    },
    extensionCheck: {
      type: String,
      default: '',
    },
    exampleType: {
      type: String,
      default: undefined,
    },
  },
  methods: {
    uploadFile() {
      let input = document.createElement("input")
      input.type = "file"
      input.onchange = (_) => {
        let file = Array.from(input.files)
        this.localFileUrl = URL.createObjectURL(file[0])
        this.fileName = file[0].name
        let extensionValid
        if(!!this.extensionCheck)
          extensionValid = this.checkExtensionFile(this.fileName)
        if(!extensionValid){
          this.localFileUrl = this.fileUrl
          this.fileName = undefined
          this.$emit("error-extension")
        }  
        else
          this.$emit("input", file[0])
      };
      input.click();
    },
    async loadFileUrl(url) {
      if (url) {
        this.runningLoader = fileLoader.getFileUrl(url);
        this.runningLoader.then((newUrl) => {
          this.localFileUrl = newUrl;
        });
      } else {
        this.localFileUrl = undefined;
      }
    },
    checkExtensionFile(fileName){
      let arrFileName = fileName.split('.')
      return(arrFileName[arrFileName.length - 1] == this.extensionCheck)
    },
    downloadExample(exampleType){
      importerService.getExampleFile(exampleType)
    }
  },
  watch: {
    fileUrl(newVal) {
      if (this.runningLoader) {
        this.runningLoader.then(() => {
          if (this.localFileUrl != newVal) {
            if (this.fileRequireToken) {
              this.loadFileUrl(newVal);
            } else {
              this.localFileUrl = newVal;
            }
          }
        });
      } else {
        if (this.localFileUrl != newVal) {
          if (this.fileRequireToken) {
            this.loadFileUrl(newVal);
          } else {
            this.localFileUrl = newVal;
          }
        }
      }
    },
  },
};
</script>

<style>
</style>